import { FooterLinkType } from '@plearn/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/plearnclub',
      },
    ],
  },
  {
    label: t('About'),
    items: [
      {
        label: t('Plearn Club'),
        href: 'https://plearnclub.com',
      },
      {
        label: t('Phill Club'),
        href: 'https://phillclub.com',
      },
      {
        label: t('Whitepaper'),
        href: `${process.env.REACT_APP_BASE_URL}/PLEARN-Whitepaper.pdf`,
      },
    ],
  },
  {
    label: t('Community'),
    items: [
      {
        label: 'Facebook',
        icon: 'Facebook',
        href: 'https://www.facebook.com/PlearnOfficialTh',
      },
      {
        label: 'Line',
        icon: 'Line',
        href: 'https://bit.ly/3s8Qi3g',
      },
      {
        label: 'Twitter',
        icon: 'Twitter',
        href: 'https://twitter.com/PlearnOfficial',
      },
      {
        label: 'Telegram',
        icon: 'Telegram',
        href: 'https://t.me/plearntoken',
      },
    ],
  },
]
